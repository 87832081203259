@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-Light.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-LightItalic.ttf") format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-Italic.ttf") format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-SemiBold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-SemiBoldItalic.ttf") format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-BoldItalic.ttf") format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-ExtraBold.ttf") format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf") format('truetype');
  font-weight: 800;
  font-style: italic;
}
